import React, { useState, useEffect } from "react";
import {
    Grid,
    Box,
    Container,
    Typography,
    List,
    ListItem,
    makeStyles,
    TextField,
    Button,
    FormHelperText,
    Link,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yep from "yup";
import Scroll from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import Logo from "src/component/Logo";
import { AiOutlineMail } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { FiFacebook, FiTwitter } from "react-icons/fi";
import FacebookIcon from "@material-ui/icons/Facebook";
import {
    FaInstagram,
    FaTelegramPlane,
    FaDiscord,
    FaFacebookF,
    FaTwitter,
    FaGithubAlt,
    FaYoutube,
} from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
    colorFFF: {
        color: "#fff",
    },
    footerSection: {
        backgroundImage:
            "linear-gradient(to right, #122272 0%, #1940b0 100%) !important",
        // background: "#F3F5F6",
        paddingTop: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(4),
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(4),
        },
        "& h1": {
            fontWeight: "600",
            fontSize: "31px",
            [theme.breakpoints.down("md")]: {
                fontSize: "33px",
            },

            [theme.breakpoints.down("sm")]: {
                fontSize: "23px",
            },
        },
        "& h6": {
            fontWeight: "400",
            fontSize: "14px",

            color: "#B7B7B7",
        },
        "& h3": {
            fontWeight: "600",
            fontSize: "18px",
            color: "#fff",
        },
        "& h4": {
            fontWeight: "600",
            fontSize: "18px",
            // color: theme.palette.text.primary,
            color: "#fff",
            marginBottom: "15px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "17px",
            },
        },
        "& .subpart": {
            borderBottom: "",
            padding: "60px 10px 24px 10px",
            "& h6": {
                fontSize: "13px",
                color: "#848484",
                fontWeight: "400",
                lineHeight: "19px",
                width: "100%",
                maxWidth: "365px",
                "@media(max-width:730px)": {
                    fontSize: "13px",
                    maxWidth: "264px",
                },
                "@media(max-width:599px)": {
                    fontSize: "13px",
                    width: "100%",
                    maxWidth: "100%",
                    marginBottom: "10px",
                },
            },
            "@media(max-width:599px)": {
                padding: "20px 10px 24px 10px",
            },
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
        },
    },
    baseSection: {
        "& p": {
            color: "#B7B7B7",
            fontSize: "12px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
            },
        },
        "@media(max-width:565px)": {
            display: "flex",
            flexDirection: "column",
        },
    },
    textFeild: {
        backgroundColor: "#fff",
        boxShadow: "0px 35px 55px -12px rgba(57, 49, 117, 0.1)",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "11px",
        borderRadius: "10px",
        "& input": {
            color: "#7B838F",
            paddingLeft: "10px",
        },
    },

    icon: {
        cursor: "pointer",
        color: "#fff",
        background: "#3C3C3C",
        marginRight: "10px",
        fontSize: "18px",
        padding: "6px",
        borderRadius: "25px",
        "& :hover": {
            color: "#3DA1E5",
        },
    },

    // socialIcons: {
    //   // background: "#3C3C3C",
    //   "& :hover": {
    //     color: "red",
    //   },
    // },
    textBtn: {
        color: "#fff",
        width: "160px",
        fontSize: "15px",
        padding: "10px 10px !important",
        background:
            "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
        borderRadius: "10px",
        fontWeight: "500",
    },
    listbox: {
        background: "none",
        border: "none",
        margin: "0px",
        "& a": {
            color: "#fff",
            display: "block",
            fontSize: "14px",
            fontWeight: "400",
            paddingTop: "10px",
        },
    },
    // fixedborder: {
    //   borderRight: "1px solid #E9C856",
    //   [theme.breakpoints.down("sm")]: {
    //     borderRight: "none",
    //   },
    // },
}));
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.white,
        // border: "1px solid ",
    },
    tooltip: {
        textTransform: "capitalize",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        // border: "1px solid #ee1d23",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}
export default function Footer() {
    const handleFormSubmit = async (values) => { };
    const classes = useStyles();
    const history = useHistory();

    // GetAllCommunityForWebsite

    const [communityLinks, setcommunityLinks] = useState();
    const [isLoading, setisLoading] = useState(false);

    return (
      <>
        <Box className={classes.footerSection}>
          <Box>
            <Box className="subpart">
              <Container>
                <Grid container>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box>
                      {" "}
                      <img
                        src="/images/logo.png"
                        alt=""
                        width="100%"
                        style={{ maxWidth: "80px", margin: "0 auto" }}
                      />
                    </Box>{" "}
                    <Box mt={1} mb={2}>
                      <Typography variant="h6">
                        CRED supports to build the bridge between the buyers and
                        sellers, by Providing the coins for Daily Needs
                        Purchase. It will also helps to provide CASHBACK for all
                        purchase using Nearmalls Payment App.
                      </Typography>{" "}
                    </Box>
                    <Box className={classes.socialIcons}>
                      {/* <Link href="https://www.telegram.com" target="_blank">
                      <FaTelegramPlane className={classes.icon} />
                    </Link> */}
                      {/* <Link href="https://www.google.com" target="_blank">
                                            <AiOutlineGooglePlus className={classes.icon} />
                                        </Link> */}
                      <Link
                        href="https://twitter.com/Credcoinpay"
                        target="_blank"
                      >
                        <FiTwitter className={classes.icon} />
                      </Link>
                      <Link
                        href="https://www.instagram.com/credcoinpay/"
                        target="_blank"
                      >
                        <AiFillInstagram className={classes.icon} />
                      </Link>
                      <Link
                        href="https://github.com/CredCoinPay"
                        target="_blank"
                      >
                        <FaGithubAlt className={classes.icon} />
                      </Link>
                      <Link
                        href="https://www.youtube.com/channel/UCdCBZznuOuUSjV5OQC_F9gA"
                        target="_blank"
                      >
                        <FaYoutube className={classes.icon} />
                      </Link>
                      <Link
                        href=" https://www.facebook.com/profile.php?id=100087783902690"
                        target="_blank"
                      >
                        <FacebookIcon className={classes.icon} />
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="h3">Company</Typography>
                        <List className={classes.listbox}>
                          {/* <ScrollLink
                                                    className="hovertext"
                                                    smooth={true}
                                                    duration={500}
                                                    to="about"
                                                    onClick={() => history.push("/?id=about")}
                                                    style={{ cursor: "pointer", color: "#7B838F" }}
                                                >
                                                    <Typography variant="h6">
                                                        About Us
                                                    </Typography>
                                                </ScrollLink> */}
                          {/* <RouterLink
                          className="hovertext"
                          style={{ textDecoration: "none", color: "#7B838F" }}
                        >
                          <ListItem>About Us</ListItem>
                        </RouterLink> */}
                          <RouterLink
                            className="hovertext"
                            style={{ textDecoration: "none", color: "#7B838F" }}
                            to="/terms"
                          >
                            <ListItem>Terms and Conditions</ListItem>
                          </RouterLink>
                          <RouterLink
                            className="hovertext"
                            style={{ textDecoration: "none", color: "#7B838F" }}
                            to="/privacy"
                          >
                            <ListItem>Privacy Policy</ListItem>
                          </RouterLink>
                        </List>{" "}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className={classes.fixedborder}
                      >
                        <Typography variant="h3">Menu</Typography>
                        <List className={classes.listbox}>
                          <ScrollLink
                            className="hovertext"
                            smooth={true}
                            duration={500}
                            to="home"
                            onClick={() => history.push("/?id=home")}
                            style={{ cursor: "pointer", color: "#7B838F" }}
                          >
                            <Typography variant="h6">Home</Typography>
                          </ScrollLink>
                          <ScrollLink
                            className="hovertext"
                            smooth={true}
                            duration={500}
                            to="about"
                            onClick={() => history.push("/?id=about")}
                            style={{ cursor: "pointer", color: "#7B838F" }}
                          >
                            <Typography variant="h6">About Us</Typography>
                          </ScrollLink>
                        </List>{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className={classes.fixedborder}
                      >
                        <Typography variant="h3">Application</Typography>
                        <List className={classes.listbox}>
                          {/* <ScrollLink
                          className="hovertext"
                          style={{ cursor: "pointer", color: "#7B838F" }}
                        >
                          Whitepaper
                        </ScrollLink> */}
                          <ScrollLink
                            className="hovertext"
                            smooth={true}
                            duration={500}
                            to="multi"
                            onClick={() => history.push("/?id=multi")}
                            style={{ cursor: "pointer", color: "#7B838F" }}
                          >
                            <Typography variant="h6">Features</Typography>
                          </ScrollLink>
                          <ScrollLink
                            className="hovertext"
                            smooth={true}
                            duration={500}
                            to="roadmap"
                            onClick={() => history.push("/?id=roadmap")}
                            style={{ cursor: "pointer", color: "#7B838F" }}
                          >
                            <ListItem>Roadmap</ListItem>
                          </ScrollLink>
                        </List>{" "}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className={classes.fixedborder}
                      >
                        <Typography variant="h3">Customer Support</Typography>
                        <List className={classes.listbox}>
                          <RouterLink
                            className="hovertext"
                            style={{ textDecoration: "none", color: "#7B838F" }}
                            to="/contact-us"
                          >
                            <ListItem> Contact Us</ListItem>
                          </RouterLink>
                          <ScrollLink
                            className="hovertext"
                            smooth={true}
                            duration={500}
                            to="faq"
                            onClick={() => history.push("/?id=faq")}
                            style={{ cursor: "pointer", color: "#7B838F" }}
                          >
                            <ListItem>FAQ</ListItem>
                          </ScrollLink>
                        </List>{" "}
                      </Grid>
                      {/* <Formik
                      onSubmit={(values) => handleFormSubmit(values)}
                      initialValues={{
                        email: "",
                      }}
                      initialStatus={{
                        success: false,
                        successMsg: "",
                      }}
                      validationSchema={yep.object().shape({
                        email: yep
                          .string()
                          .email(
                            "You have entered an invalid email address. Please try again"
                          )
                          .required("Email address is required.")
                          .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
                      })}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <Form>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.fixedborder}
                          >
                            <Box>
                              <Typography
                                variant="h4"
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "10px",
                                }}
                              >
                                Subscribe to Newsletter
                              </Typography>
                              <Box className={classes.feildBox}>
                                <TextField
                                  placeholder="Enter your mail"
                                  className={classes.textFeild}
                                  name="email"
                                  error={Boolean(touched.email && errors.email)}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  InputProps={{
                                    className: classes.bin,
                                    endAdornment: (
                                      <Button
                                        variant="contained"
                                        type="submit"
                                        color="secondary"
                                        className={classes.textBtn}
                                      >
                                        Try Now
                                      </Button>
                                    ),
                                  }}
                                />
                                <FormHelperText
                                  error
                                  style={{ fontSize: "12px" }}
                                >
                                  {touched.email && errors.email}
                                </FormHelperText>
                              </Box>
                            </Box>
                          </Grid>
                        </Form>
                      )}
                    </Formik> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
              <Box style={{ paddingTop: "12px" }} pb={3}>
                <Box style={{ borderTop: "1px solid #CFCFCF" }}></Box>
              </Box>
              <Box className={classes.baseSection} textAlign="center">
                <Typography variant="body1">
                  CRED &copy; 2022 . All Rights Reserved.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
}
