// const url = "https://java-nearmalls.mobiloitte.org";
export const urlNearMalls =
  "https://nearshopapi.smartschoolsystem.co/api/NearShopApi/Login";
// const url = "https://backend.credpay.io";
const url = "https://java-nearmalls.mobiloitte.org";

// const notificationIo = "wss://notification.credpay.io";

const user = `${url}/account`;
const auth = `${url}/auth`;
const account = `${url}/account`;
const wallet = `${url}/wallet`;
const static1 = `${url}/static`;
const admin = `${url}/wallet/admin`;
const notification = `${url}/notification`;

const ApiConfig = {
  userLogin: `${auth}`,
  getCoinList: `${url}/wallet/coin/get-coin-list`,
  userSignUp: `${user}/signup`,
  userSignUpMobileApp: `${user}/signup-for-mobile`,
  verifyOTP: `${user}/verify-user`,
  resendOTP: `${user}/resend-verify-email`,
  verifyUserphone: `${user}/verify-phone-Sms-code`,
  sendphoneNocode: `${user}/send-phoneNo-code`,
  ForgotPassword: `${user}/forget-password-mobile-app`,
  forgotPassword: `${user}/forget-password/mobiloitApp`, ///verify-sms-code-mobile-app
  verifyOTPSMS: `${user}/verify-sms-code-mobile-app`, //verify-sms-code-mobile-app
  resetPassword: `${user}/reset-password-mobile-app`,
  getKYCDetails: `${account}/get-kyc-details`,
  adminstatusforkyc: `${account}/get-check-kyc-status`,
  // editUserProfile: `${user}/profile-update`,
  getCountryList: `${user}/get-country-list`,
  getTransactionHistory: `${url}/wallet/wallet/get-all-transaction-history`,

  addSubscribe: `${user}/add-subscribe`,

  // account

  myAccount: `${account}/my-account`,
  updateUserProfile: `${account}/update-user-profile`,
  userBankAccount: `${account}/add-user-bank-account`,
  userAccountDetails: `${account}/get-user-account-detail`,
  userAccountList: `${account}/get-user-account-list`,
  userAccountDelete: `${account}/delete-user-bank-account`,
  saveKycDetails: `${account}/save-kyc-details`,
  uploadFile: `${account}/upload-file`,
  getKycList: `${account}/get-all-kyc-details`,
  editUserProfile: `${account}/profile-update`,
  CountryList: `${account}/get-country-list`,
  searhList: `${account}/search-and-filter-bank-account-list`,
  newsletter: `${account}/get-all-news-letter-for-website`,
  whitePaperList: `${account}/get-whitepaper-full-list`,

  // wallet

  myWallet: `${wallet}/get-all-user-balance-and-coinlist`,
  // static
  contactUS: `${static1}/submit-support-ticket`,
  postnewsLetter: `${static1}/post-news-letter-to-user`,
  // term: `${static1}/get-all-static-content-data`,
  faq: `${static1}/search-filter-faq-list`,
  term: `${static1}/get-static-page-data-by-page-key`,
  Aboutus: `${static1}/get-all-static-content-data`,
  policy: `${static1}/get-all-static-content-details-for-web`,
  GetAllCommunityForWebsite: `${account}/get-all-community-for-website`,
  // policy: `${static1}/get-static-page-data-by-page-key?pageKey=Privacy_Policy`,
  myWallet: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getCurrentprice: `${wallet}/wallet/get-price?`,
  toaddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,

  myAddress: `${wallet}/wallet/get-address-new`,
  withdraw: `${wallet}/wallet/withdraw`,
  withdrawNew: `${wallet}/wallet/withdraw-for-user`,
  approveWithdraw: `${wallet}/wallet/approve-withdraw`,
  approveWithdrawNew: `${wallet}/wallet/approve-withdraw-for-user`,

  cancelWithdraw: `${wallet}/wallet/cancel-withdraw`,
  deposits: `${wallet}/wallet/get-deposits`,
  depositsNew: `${wallet}/wallet/get-deposits-new`,
  buyToken: `${wallet}/basic-exchange/buy-token`,
  coinGet: `${wallet}/wallet/get-open-sale`,
  buyTokenBtc: `${wallet}/wallet/buy-token`,
  minimumfee: `${wallet}/wallet/coin/get-coin-list`,

  // admin /wallet/coin/get-coin-list
  transactionHistoy: `${wallet}/get-all-transaction-history`,

  // notification
  getNotification: `${notification}/get-notification-data`,
  readNotification: `${notification}/read-notification`,
  clearNotification: `${notification}/clear-notification`,

  // account
};

export default ApiConfig;
