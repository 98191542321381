import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  Typography,
} from "@material-ui/core";
import { FaMobileAlt } from "react-icons/fa";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "src/component/Logo";
import Scroll from "react-scroll";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popover from "@material-ui/core/Popover";
import { RiMenu5Fill } from "react-icons/ri";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { saveAs } from "file-saver";
import { ExpandLess } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NoDataFound from "src/DataNotFound";
import { withStyles } from "@material-ui/core/styles";
import { FaApple, FaGoogle } from "react-icons/fa";

const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "rgba(255,255,255,1)",
    // position: "fixed",
    // background: "#000",
    top: "0px",
    border: "none",
    left: "0",
  },
  menuButton2: {
    // background: "#EE1D23",

    fontSize: "15px",
    lineHeight: "15px",
    fontWeight: "500",
    alignItems: "center",
    backgroundColor: "transparent",
    borderColor: "rgba(255,255,255,1)",
    borderRadius: "9999px",
    borderWidth: "2px",
    color: "rgba(255,255,255,1)",
    fontWeight: "700",
    justifyContent: "center",
    textAlign: "center",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    width: "170px",
    height: "45px",
  },
  menuButton: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "19px",
    color: "#fff",

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#1ed760",
    },
    "&:hover": {
      color: "#d00167",
    },
  },
  menuButton1: {
    width: "100%",
  },
  toolbar: {
    display: "flex",
    padding: "2px 0px 2px 0px",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#0138ac",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media(max-width:1219px)": {
      padding: "0px 0px",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "19px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",

    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",

    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
    padding: "0px !important",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "10px 0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },

  typosec: {
    // fontFamily: ""Inter" !important",
    color: "#fff !important",
    // color: `${theme.palette.text.mainColor} !important `,
    "&[tabIndex]:focus": {
      color: "#0fb2ee !important",
      borderBottom: "1px solid #0fb2ee !important",
    },
  },
  containerbox: {
    position: "fixed",
    top: "0",
    zIndex: "999",

    background: theme.palette.background.default,

    width: "100%",
  },
  barimage: {
    padding: "20px",
    maxWidth: "162px",
    textAlign: "center",
  },
}));

export default function Header({ buttonClick }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };
  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const {
    menuButton2,
    appBar,
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    barimage,

    submenu,
  } = useStyles();
  const history = useHistory();
  const location = useLocation();
  // console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const [open, setOpen] = useState(false);
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const StyledMenu = withStyles({
    paper: {
      marginTop: "10px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // const open = Boolean(anchorEl);

  const [whitePaper, setWhitePaper] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  console.log("whitePaper--->>>", whitePaper);
  const listWhitePaperHandler = async () => {
    setisLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.whitePaperList,
      });
      if (res.data.status === 200) {
        setWhitePaper(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  useEffect(() => {
    listWhitePaperHandler();
  }, []);

  const saveFile = (file) => {
    saveAs(file?.file, `${file?.language}_whitePaper.pdf`);
  };
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0" style={{ padding: "0px" }}>
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
            className="activeClass"
          >
            <nav>
              <input type="checkbox" id="check" />

              <ul className="navigation">
                <ScrollLink
                  onClick={() => history.push("/?id=home")}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="home"
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Home
                  </Typography>
                </ScrollLink>
                <ScrollLink
                  onClick={() => history.push("/?id=about")}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="about"
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    About Us
                  </Typography>
                </ScrollLink>
                <ScrollLink
                  onClick={() => history.push("/?id=multi")}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="multi"
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Features
                  </Typography>
                </ScrollLink>
                <ScrollLink
                  onClick={() => history.push("/?id=faq")}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="faq"
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    FAQ's
                  </Typography>
                </ScrollLink>
                <ScrollLink
                  onClick={() => history.push("/?id=roadmap")}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="roadmap"
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Roadmap
                  </Typography>
                </ScrollLink>
                <ScrollLink
                  // onClick={() => history.push("/")}
                  className="hovertext"
                  // smooth={true}
                  // duration={500}
                  // to="about"
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onClick={handlePopoverOpen}
                    // disabled={whitePaper.length == 0}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    // onClick={handlePopoverOpen}
                    // onMouseLeave={handlePopoverClose}
                  >
                    Whitepaper
                    {anchorEl ? (
                      <ExpandLess className={`${classes.typosec} hovertext`} />
                    ) : (
                      <ExpandMoreIcon
                        className={`${classes.typosec} hovertext`}
                      />
                    )}
                  </Typography>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    // keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {whitePaper?.map((data, i) => {
                      return (
                        <MenuItem onClick={() => saveFile(data)}>
                          {data?.language}
                        </MenuItem>
                      );
                    })}
                    {whitePaper?.length == 0 ||
                      (whitePaper == undefined && (
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            fontWeight: "300",
                          }}
                        >
                          No Whitepaper Found
                        </Typography>
                      ))}
                  </Menu>
                </ScrollLink>
                <IconButton onClick={handleClick5} style={{ color: "#fff" }}>
                  <FaMobileAlt />
                </IconButton>
                <li>
                  <Button
                    target="_blank"
                    onClick={() => window.open("https://user.credpay.io")}
                    variant="contained"
                    color="secondary"
                    className="loginbutton"
                    style={{
                      height: "40px",
                      margin: "0 10px",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      fontSize: "18px",
                      padding: "5px 24px",
                    }}
                  >
                    Login
                  </Button>
                </li>
              </ul>
            </nav>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "35px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return (
      <>
        <nav>
          <input type="checkbox" id="check" />

          <label for="check" className="checkbtn">
            <RiMenu5Fill size={22} style={{ color: "#ffff" }} />
          </label>
          <label clasnames="logo">
            <Logo style={{ margin: "10px 0 10px 16px", width: "80px" }} />
          </label>
          <ul className="navigation">
            <ScrollLink
              onClick={() => history.push("/")}
              className="hovertext"
              smooth={true}
              duration={500}
              to="home"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                Home
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              className="hovertext"
              smooth={true}
              duration={500}
              to="about"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                About Us
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              className="hovertext"
              smooth={true}
              duration={500}
              to="multi"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                Features
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              className="hovertext"
              smooth={true}
              duration={500}
              to="faq"
              style={{ cursor: "pointer" }}
            >
              {" "}
              <Typography variant="h6" paragraph className="hovertext">
                FAQ's
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              className="hovertext"
              smooth={true}
              duration={500}
              to="roadmap"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                Roadmap
              </Typography>
            </ScrollLink>
            {/* <ScrollLink
              onClick={() => history.push("/")}
              className="hovertext"
              smooth={true}
              duration={500}
              to="choose"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                Contact Us
              </Typography>
            </ScrollLink> */}
            {whitePaper?.length == 0 ? (
              ""
            ) : (
              <>
                <ScrollLink
                  // onClick={() => history.push("/")}
                  className="hovertext"
                  // smooth={true}
                  // duration={500}
                  // to="about"
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onClick={handlePopoverOpen}
                    style={{
                      display: "flex",
                      alignItem: "center",
                    }}
                  // onClick={handlePopoverOpen}
                  // onMouseLeave={handlePopoverClose}
                  >
                    Whitepaper{" "}
                    {anchorEl ? (
                      <ExpandLess className={`${classes.typosec} hovertext`} />
                    ) : (
                      <ExpandMoreIcon
                        className={`${classes.typosec} hovertext`}
                      />
                    )}
                  </Typography>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    // keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {whitePaper?.map((data, i) => {
                      return (
                        <MenuItem onClick={() => saveFile(data)}>
                          {data?.language}
                        </MenuItem>
                      );
                    })}

                    {whitePaper?.length == 0 ||
                      (whitePaper == undefined && (
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            fontWeight: "300",
                          }}
                        >
                          No Whitepaper Found
                        </Typography>
                      ))}
                  </Menu>
                </ScrollLink>
              </>
            )}
            <li>
              <Button
                target="_blank"
                onClick={() => window.open("https://user.credpay.io/login")}
                variant="contained"
                color="secondary"
                className="loginbutton"
                style={{
                  height: "40px",
                  margin: "0 10px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                  fontSize: "18px",
                  padding: "5px 24px",
                }}
              >
                Login
              </Button>
            </li>
          </ul>
        </nav>
      </>
    );
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return (
      <>
        <Button
          {...{
            component: Link,
            className: menuButton,
          }}
        ></Button>
      </>
    );
  };

  return (
    <>
      <Box className={classes.containerbox}>
        <Container>
          <AppBar
            className={appBar}
            position={
              history.location.pathname !== "/" ? "relative" : "relative"
            }
            elevation={0}
            style={{ background: "none" }}
          >
            <Container
              maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
              className={containerHeight}
            >
              {mobileView ? displayMobile() : displayDesktop()}
            </Container>
          </AppBar>
        </Container>
      </Box>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl5}
        keepMounted
        open={Boolean(anchorEl5)}
        onClose={handleClose5}
      >
        <Box className={barimage}>
          <Box>
            <img src="/images/barcode.png" width="100%" />
          </Box>
          <Typography variant="h6" style={{ color: "#000" }}>
            Scan to Download App IOS & Android
          </Typography>
          <Box mt={1}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              startIcon={<FaGoogle style={{ fontSize: "16px" }} />}
              style={{
                whiteSpace: "pre",
                fontSize: "14px",
                fontWeight: "500",
                padding: "7px 35px",
              }}
            >
              App Store
            </Button>
            <Box mt={1}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<FaApple />}
                style={{
                  whiteSpace: "pre",
                  fontSize: "14px",
                  fontWeight: "500",
                  padding: "7px 27px",
                }}
              >
                Google play
              </Button>
            </Box>
          </Box>
        </Box>
      </StyledMenu>
    </>
  );
}
