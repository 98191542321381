import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiSelect: {
      icon: {
        color: "#000",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "0px",
        "@media (min-width: 600px)": {
          minHeight: "0px !important",
        },
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        // color: "#fff",
        width: "40px",
        height: "40px",
        overflow: "visible",
        borderRadius: "50%",

        "@media (max-width: 767px)": {
          width: "40px",
          height: "40px",
        },
        "&.Mui-disabled": {
          "& .MuiSvgIcon-root": {
            color: "rgba(0, 0, 0, 0.26)",
          },
        },
      },
      edgeEnd: {
        marginRight: " 0px",
      },
    },
    MuiTableRow: {
      root: {
        // border: "1px solid #3a96dd ",
      },
    },
    MuiDialogActions: {
      root: {
        marginRight: "14px",
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: "none",
      },
    },
    MuiMenu: {
      list: {
        padding: "10px ",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "8px !important",
        marginBottom: "5px !important",
        /* height: 33px; */
      },
      root: {
        border: "0",
        width: "100%",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
      },
    },
    MuiCheckbox: {
      root: {
        width: "10px",
        height: "10px",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
        color: "white",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "8px",
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: "6px",
        paddingTop: "0x",
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiTableCell: {
      alignCenter: {
        textAlign: "left",
      },
      alignRight: {
        textAlign: "left",
      },
      root: {
        borderBottom: "none",
        padding: "12px 6px 14px 10px",
        textAlign: "left",
        fontSize: "13px",
      },
      body: {
        color: "#fff",
      },
      head: {
        color: "#fff",
        background: "#0138ac",
        fontSize: "14px",
        fontWeight: "600",
        textAlign: "center",
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: "#000",
      },

      caption: {
        color: "#A7A1A1",
        fontSize: "12px",
      },
      subtitle2: {
        color: "#fff",
        lineHeight: "20px !important",
      },
      colorTextSecondary: {
        color: "white",
      },
    },
    MuiLink: {
      underlineHover: { color: "#747474" },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#fff",
        "&.Mui-selected": {
          color: "#fff",
        },
      },
    },

    MuiButton: {
      root: { color: "#fff" },
      containedSecondary: {
        boxShadow: "0px 13px 27px rgba(0, 0, 0, 0.25)",
        borderRadius: "30px",
        color: "#fff",
        fontSize: "14px",
        background:
          "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
        padding: "13px 30px !important",
        "&:hover": {
          backgroundColor: "#1069c2",
          color: "#fff",
        },
        "@media (max-width: 600px)": {
          padding: "7px 15px !important",
        },
      },
      outlinedSecondary: {
        borderRadius: "5px",
        color: "#3a96dd",
        fontSize: "18px",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        fontWeight: 400,
        padding: "9px 25px",
        border: "1px solid #3a96dd",
        "&:hover": {
          border: "1px solid #3a96dd",
          backgroundColor: "#3a96dd",
          color: "#fff",
        },
        "@media (max-width: 600px)": {
          padding: "7px 15px !important",
        },
      },
      containedPrimary: {
        backgroundColor: "#fff",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        color: "#fff",
        fontSize: "18px",
        fontWeight: "500",
        background:
          "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
        lineHeight: " 21px",
        padding: "9px 25px",
        marginRight: "10px",
        "&:hover": {
          background:
            "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%)",
          color: "#fff",
        },
      },
      contained: {
        borderRadius: "5px",
        color: "#000",
        fontSize: "18px",
        fontWeight: "500",

        padding: "5px 19px",

        "&:hover": {
          backgroundColor:
            "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
          color: "#fff",
        },
      },
      outlinedPrimary: {
        borderRadius: "30px",
        color: "#848484",
        fontSize: "16px",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        fontWeight: 400,
        padding: "13px 30px !important",
        border: "1px solid #3a96dd",
        "&:hover": {
          background:
            "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
          color: "#fff",
        },
        "@media (max-width: 600px)": {
          padding: "7px 15px !important",
        },
      },
      containedSizeSmall: {
        padding: "6px 23px",
        fontSize: "14px",
        lineHeight: " 24px",
        height: "44px",
        borderRadius: "10px",
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "14px",
        lineHeight: " 24px",
        height: "44px",
        borderRadius: "10px",
      },
      textPrimary: {
        color: "white",
      },
    },
    MuiTableRow: {
      root: {
        // border: "1px solid #121212",
      },
    },
    MuiPaper: {
      rounded: {
        border: "20px",
      },
      root: {
        // backgroundColor: "#302F35",
        background:
          "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%)",
        color: "#fff",
      },
      outlined: {
        backgroundColor: "#2A292E",
        border: "1px solid #797979",
      },
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "#FF2626",
      },
    },
    MuiDropzoneArea: {
      root: {
        backgroundColor: "transparent",
        border: "1px dashed #5d5656 !important",
        minHeight: "170px !important",
      },
    },

    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
        marginRight: "0px",
        fontSize: "12px !important",
      },
    },

    MuiInputBase: {
      input: {
        "&.Mui-disabled": {
          // border: "1px solid #312e2e",
          borderRadius: "4px",
        },
        input__webkit_autofill: {
          WebkitBackgroundClip: "text !important",
          WebkitTextFillColor: "red !important",
        },
        // "& ::-webkit-autofill": {
        //   color: "#000 !important",
        // },
      },
      root: {
        fontSize: "13px",
      },
    },
    MuiPopover: {
      paper: {
        marginTop: "44px",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: { backgroundColor: "transparent !important" },

      dayLabel: {
        color: "#fff",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#fff",
      },
      daySelected: {
        backgroundColor: "#fff",
        // "& :hover": {
        //   backgroundColor: "#fff",
        // },
      },
      "& :hover": {
        backgroundColor: "#fff",
      },
    },
    //     .MuiPickersDay-daySelected:hover {
    //     background-color: #fff;
    // }
    MuiSelect: {
      iconOutlined: {
        // color: "#fff",
        right: "10px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#848484",
      },
    },
    MuiAlert: {
      standardError: {
        color: "none",
        backgroundColor: "none",
      },
    },

    MuiPagination: {
      ul: {
        background: "none !important",
        height: "41px !important",
        width: "auto !important",
      },
    },
    MuiDrawer: {
      paper: {
        overflowY: "none",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "44px",
        background: "#F3F5F6",
        borderRadius: "9px",
        "& ::-webkit-input-placeholder": {
          color: "#000 !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #fff",
          borderColor: "#fff",
        },
        input: {
          "& ::-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            webkitBackgroundClip: "text !important",
            webkitTextFillColor: "#1f1e1e !important",
          },
        },
      },
      notchedOutline: {
        // border: "1px solid #ececec",
        // borderColor: "#ececec",
      },
      adornedEnd: {
        paddingRight: "6px",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "none",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "none",
      },
      toolbarBtnSelected: {
        color: "none",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiInputBase: {
        root: {
          color: "#000 !important",
        },
        input: {
          color: "#000 !important",
        },
      },
      MuiTableRow: {
        root: {
          // border: "1px solid #3a96dd ",
        },
      },
      // MuiPaper: {
      //   root: {
      //     // backgroundColor: "#302F35",
      //     background:
      //       "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%) !important",
      //     color: "#fff !important",
      //   },
      // },
      MuiFormLabel: {
        root: {
          color: "#1D2D3F",
          marginTop: "0",
          "&.Mui-focused": { color: "#222" },
        },
      },

      MuiTableCell: {
        body: {
          color: "#000",
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "#0138ac",
        dark: "#fff",
        virtual: "#F8FBFF",
        paper: colors.common.white,
        taf: "#F3F5F6",
        back: "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
        cardstyle: "#245CA3",
        About: "#fff",
        dashLayout: "#3A96DD",
        Notification: "#fff",
        login: "#e5e5e5",
      },
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#fff",
      },
      tertiary: {
        main: "#ccc",
      },
      text: {
        primary: colors.blueGrey[900],
        mainColor: "#000",
        secondary: colors.blueGrey[600],
        NotificationColor: "green",
        SideBar: "#263238",
        token: "#1940b0",
        subtext: "#415076",
        blueblack: "#12183a",
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    overrides: {
      MuiInputBase: {
        root: {
          color: "#fff !important",
        },
        input: {
          color: "#fff !important",
        },
      },
      MuiTableRow: {
        root: {
          // border: "1px solid #3a96dd ",
        },
      },
      // MuiPaper: {
      //   root: {
      //     // backgroundColor: "#302F35",
      //     background: "#1c1d1f !important",

      //     color: "#fff !important",
      //   },
      // },

      MuiFormLabel: {
        root: {
          color: "#fff",
          marginTop: "0",
          "&.Mui-focused": { color: "#f5f5f5" },
        },
      },
      MuiTableCell: {
        body: {
          color: "#fff",
        },
      },
    },
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#000",
        virtual: "#0c1012",
        taf: "#0c1012",
        paper: "#282C34",
        back: "#18293b",
        cardstyle: "#40474f",
        About: "#000",
        dashLayout: "#0c1012",
        Notification: "#373737",
        login: "#343434",
      },
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#1f73b7",
      },
      warning: {
        main: "#BC211D",
      },
      error: {
        main: "#B33A3A",
      },
      text: {
        primary: "#fff",
        mainColor: "#fff",
        secondary: "#F8FBFF",
        NotificationColor: "#fff",
        SideBar: "#fff",
        token: "#fff",
        subtext: "#fff",
        blueblack: "#fff",
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
