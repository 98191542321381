import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FiSun } from "react-icons/fi";
import SettingsContext from "src/context/SettingsContext";
import { FaRegMoon } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowX: "auto",
    backgroundColor: theme.palette.background.dark,
    // background: "#fff",
    color: theme.palette.text.primary,
    // color: "#000",
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainbox: {
    // boxShadow: "0px 30px 30px rgba(0, 0, 0, 0.3)",
    // backgroundColor: theme.palette.background.login,
    // backdropFilter: "blur(42px)",
    color: theme.palette.text.primary,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    backgroundColor: "#122272",
    "& h2": {
      color: "#fff",
    },
    "& h1": {
      color: "#fff",
      marginBottom: "16px",
      fontSize: "32px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "28px",
        lineHeight: "41px",
      },
      "@media(max-width:375px)": {
        fontSize: "22px",
        lineHeight: "33px",
      },
    },
  },
  logoImage: {
    position: "absolute",
    zIndex: "1",
    top: "20px",
    cursor: "pointer",
    width: "100%",
    maxWidth: "100px",
    "@media(max-width:1279px)": {
      display: "none",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = React.useContext(SettingsContext);
  // console.log("sjflskf", themeSeeting);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
    // console.log("ThemeType", type);
  };
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} className={classes.left}>
          <Hidden mdDown>
            <Container>
              <img
                src="images/logo.png"
                alt=""
                className={classes.logoImage}
                // className="Unoique"
                onClick={() => history.push("/")}
              />
            </Container>
          </Hidden>
          <Box
            className={classes.mainbox}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box className="signupmain">
              <Box align="center">
                {window.location.pathname === "/login" ||
                window.location.pathname === "/signup" ? (
                  <img
                    src="images/signUpImg.png"
                    alt=""
                    // className="Unoique"
                    // onClick={() => history.push("/")}
                  />
                ) : window.location.pathname === "/forget-password" ||
                  window.location.pathname === "/verify-otp" ||
                  window.location.pathname === "/reset-password" ? (
                  <img
                    src="images/verifyImg.png"
                    alt=""
                    style={{ marginBottom: "30px" }}
                    // className="Unoique"
                    // onClick={() => history.push("/")}
                  />
                ) : (
                  ""
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                style={{ marginTop: "-30px" }}
              >
                <Typography variant="h2">Welcome to</Typography>
                <Typography variant="h1">CRED</Typography>
              </Box>{" "}
              {/* <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <img
                src="/images/logo.png"
                style={{ width: "350px", margin: "20px 0" }}
                alt=""
                onClick={() => history.push("/")}
              />
            </Box> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box className={classes.content}>{children}</Box>
        </Grid>
      </Grid>
      {/* <Box className="themeButton">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            changeTheme("LIGHT");
          }}
        >
          <FiSun />
        </IconButton>
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            changeTheme("DARK");
          }}
        >
          <FaRegMoon />
        </IconButton>
      </Box> */}
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
